<template>
  <div
    class="
      fixed
      inset-0
      z-30
      bg-white
      flex flex-col
      items-center
      text-xl
      overflow-scroll
    "
  >
    <div class="my-12">Katie Paterson &mdash; <em>Ideas</em></div>
    <div class="flex-grow flex items-center">
      <div class="max-w-lg text-center space-y-3 px-8">
        <p>
          Create your own journey to view the work, using the green points on
          the map to locate, find and mark the <em>Ideas</em> you have visited.
        </p>
        <p>
          Please allow location tracking. Your progress will be saved on your
          device.
        </p>
      </div>
    </div>
    <div class="my-12 text-center">
      <a
        class="text-sm mb-8 mx-6 max-w-lg leading-none mb-2 underline"
        href="https://cms.ideas.is.ed.ac.uk/accessibility-statement"
        >Click here for the Accessibility Statement</a
      >
      <p class="text-sm mb-8 mx-6 max-w-lg leading-none">
        The app uses
        <a
          href="https://www.mapbox.com"
          title="mapbox"
          target="_blank"
          class="underline"
          >mapbox</a
        >
        to display a map, please refer to their
        <a
          href="https://www.mapbox.com/legal/privacy"
          title="Privacy Policy"
          target="_blank"
          class="underline"
          >privacy policy</a
        >
        for more information.
      </p>
      <button
        @click="$emit('begin')"
        aria-label="Accept cookies and proceed"
        class="
          border border-black
          rounded-full
          py-3
          px-8
          hover:bg-black hover:text-white
          mx-auto
        "
      >
        Accept Cookies
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
}
</script>
