<template>
  <div id="app">
    <transition name="fade">
      <Intro v-if="showIntro" @begin="begin" />
    </transition>
    <Menu v-if="$route.name !== 'Location'" />
    <transition name="fade">
      <router-view v-if="cookiesAccepted" />
    </transition>
    <a
      tabindex="1"
      href="https://cms.ideas.is.ed.ac.uk/accessibility-statement"
      class="
        fixed
        bg-white
        right-4
        bottom-4
        md:right-0 md:bottom-0 md:w-72
        px-3
        py-1
        z-10
        text-xs text-center
        rounded
        shadow-sm
      "
    >
      Accessibility Statement
    </a>
  </div>
</template>

<script>
import 'lazysizes'
import Menu from '@/components/Menu'
import Intro from '@/components/Intro'
export default {
  name: 'App',
  components: {
    Intro,
    Menu,
  },
  data() {
    return {
      showIntro: false,
      cookiesAccepted: false,
    }
  },
  mounted() {
    this.$store.commit('INIT')

    if (this.$cookies.isKey('KPIDEAS_allowcookies')) {
      if (this.$cookies.get('KPIDEAS_allowcookies')) {
        this.cookiesAccepted = true
      }
    } else {
      this.showIntro = true
    }

    if (this.$cookies.isKey('KPIDEAS_found')) {
      this.$store.commit(
        'FOUND',
        JSON.parse(this.$cookies.get('KPIDEAS_found'))
      )
    }
  },
  methods: {
    begin() {
      this.$cookies.set('KPIDEAS_allowcookies', true, '14d')
      this.cookiesAccepted = true
      this.showIntro = false
    },
  },
  computed: {
    items() {
      return this.$store.state.items
    },
    currentItem() {
      if (this.items) {
        return this.items.find((x) => x.id === this.$route.params.id)
      } else {
        return false
      }
    },
  },
}
</script>

<style>
@import './css/app.css';
</style>
