import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		items: null,
		showMenu: false,
		foundItems: []
	},
	mutations: {
		INIT (state) {
			fetch(`${process.env.VUE_APP_API_URL}/locations.json`, {
				mode: 'cors',
			}).then((res) => {
				res.json().then((json) => (state.items = json))
			})
		},
		MENU (state, payload) {
			state.showMenu = payload
		},
		FOUND (state, payload) {
			state.foundItems = payload
			VueCookies.set('KPIDEAS_found', JSON.stringify(payload))
		}
	},
	actions: {
		toggleFound ({ state, commit }, id) {
			let items = state.foundItems

			if (!items.includes(id)) {
				items.push(id)
			} else {
				const index = items.indexOf(id)
				if (index > -1) {
					items = items.splice(index, id)
				}
			}
			commit('FOUND', items)
		}
	},
	modules: {}
})
