<template>
  <div>
    <transition name="fade">
      <button
        aria-label="View about text"
        v-if="showHamburger"
        class="
          w-16
          h-16
          rounded-full
          fixed
          top-0
          left-0
          z-20
          bg-white
          m-6
          flex
          items-center
          justify-center
          cursor-pointer
          shadow-lg
        "
        @click="$store.commit('MENU', true)"
      >
        <svg
          width="35"
          height="16"
          viewBox="0 0 35 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0.121948"
            y1="0.743896"
            x2="34.878"
            y2="0.743896"
            stroke="black"
          />
          <line
            x1="0.121948"
            y1="7.69513"
            x2="34.878"
            y2="7.69513"
            stroke="black"
          />
          <line
            x1="0.121948"
            y1="14.6463"
            x2="34.878"
            y2="14.6463"
            stroke="black"
          />
        </svg>
      </button>
    </transition>

    <transition name="fade">
      <div id="menu" v-if="showMenu" class="md:text-2xl md:max-w-lg md:ml-auto">
        <div class="flex flex-col">
          <div class="flex justify-between text-lg w-full p-6 py-4">
            <button @click="closeMenu">Close</button>
            <span class="space-x-5">
              <a
                href="#about"
                @click.prevent="scrollTo('about')"
                title="Scroll to About"
                >About</a
              >
              <span>/</span>
              <a
                href="#your-journey"
                @click.prevent="scrollTo('your-journey')"
                title="Scroll to Your Journey"
                >Your Journey</a
              >
              </span
            >
          </div>
          <div class="overflow-y-auto h-screen">
            <h2 id="your-journey" class="ml-6 mb-4 mt-6 md:mt-12 text-2xl">
              Your Journey
            </h2>
            <div
              class="mb-3 px-6 pb-16 md:pb-16 underline-links text-lg space-y-3"
              v-html="journeyText.content"
            ></div>
            <h2 id="about" class="ml-6 mb-4 md:mt-12 text-2xl">About</h2>
            <div
              class="
                mb-3
                px-6
                pb-48
                md:pb-16
                mb-12
                underline-links
                text-lg
                mb-8
                space-y-3
              "
              v-html="aboutText.content"
            ></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      showAbout: false,
      journeyText: null,
      aboutText: null,
    }
  },
  computed: {
    ...mapState(['items', 'showMenu']),
    showHamburger() {
      return !this.$store.state.showMenu && !this.$store.state.currentItem
    },
    foundItems() {
      return this.$store.state.foundItems.length
    },
  },
  methods: {
    found(id) {
      return this.$store.state.foundItems.includes(id)
    },
    closeMenu() {
      this.$store.commit('MENU', false)
      this.$router.replace({ hash: '' })
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
      this.$router.replace({ hash: '#' + id })
    },
  },
  mounted() {
    fetch(`${process.env.VUE_APP_API_URL}/your-journey.json`, {
      mode: 'cors',
    }).then((res) => {
      res.json().then((json) => (this.journeyText = json))
    })
    fetch(`${process.env.VUE_APP_API_URL}/about.json`, {
      mode: 'cors',
    }).then((res) => {
      res.json().then((json) => (this.aboutText = json))
    })
  },
  watch: {
    $route(to) {
      if (!to.hash) {
        this.showAbout = false
      }
    },
  },
}
</script>

<style lang="css">
#menu {
  @apply fixed bg-white z-40;
}
</style>
